import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import AuthService from './AuthService';
import Container from 'react-bootstrap/Container';
import './Events.css'

export default function Logfile(props) {
  const [txt, setTxt] = useState("");

  const { uuid } = useParams();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_EVENTS}/${uuid}/display`, {
      method: 'GET',
      headers: {
        Authorization: new AuthService().getToken()
      }
    })
    .then(resp => resp.text())
    .then(data => setTxt(data))
  }, [uuid])

  return <>
    <nav class="navbar navbar-expand-lg navbar-light bg-white">
    </nav>
    <Container>
    <Table striped bordered>
      <tbody>
      {txt.split('\n').map(function(item) {
        var column = item.split(',');
        return <tr>
          <td>{column[0]}</td>
          <td>{column[1]}</td>
          <td>{column[2]}</td>
          <td>{column[3]}</td>
          <td>{column[4]}</td>
          <td>{column[5]}</td>
          <td>{column[6]}</td>
          </tr>;})}
      </tbody>
    </Table>
    </Container>
    </>

}
