import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import AuthService from './AuthService';
import Container from 'react-bootstrap/Container';
import './Events.css'

export default function Zipfile(props) {
  const { uuid } = useParams();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_EVENTS}/${uuid}/zip`, {
      method: 'GET',
      headers: {
        Authorization: new AuthService().getToken()
      }
    })
    .then(resp => resp.blob())
    .then(blob => {
      var file = window.URL.createObjectURL(blob);
      let link = document.createElement('a');
      link.href = file;
      link.download = `${uuid}.zip`;
      link.click();

      // For Firefox it is necessary to delay revoking the ObjectURL.
      setTimeout(() => { window.URL.revokeObjectURL(file); }, 250);

      // works but uses random download filename
      //window.location.assign(file);
    })
  }, [uuid])

  return <Navigate to='/' />

}
