import React, { Component } from 'react';
import AuthService from './AuthService';
import  { Navigate } from 'react-router-dom';

class Logout extends Component {
  constructor(props) {
    super(props);
    this.Auth = new AuthService();
  }
  
  render() {
    this.Auth.logout();
    return (
        <Navigate to='/login' />
      );
  }

}

export default Logout;
