import React, { Component } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { Link, Navigate  } from "react-router-dom";
import AuthService from './AuthService';
import './Events.css'
import ActionCable from 'action-cable-react-jwt';
import 'bootstrap/dist/css/bootstrap.min.css';

class Events extends Component {
  state = { items: [] }

  componentDidMount() {
    const bearerToken = new AuthService().getToken();
    if(bearerToken != null) {
      const token = bearerToken.substring(7, bearerToken.length);
      window.fetch(process.env.REACT_APP_EVENTS, {
        method: 'GET',
        headers: {
          Authorization: bearerToken
        }
      }).then(data => {
        data.json().then(res => {
          this.setState({ items: res })
        })
      })

      const cable = ActionCable.createConsumer(process.env.REACT_APP_CABLE,token)
      this.sub = cable.subscriptions.create('EventsChannel', {
        received: this.handleReceiveNewEvent
      })
    }
  }

  handleReceiveNewEvent = (event) => {
    this.setState({ items: this.state.items.concat(event) })
  }

  handleDeleteEvent = (eventId) => {
    if (window.confirm('Are you sure you want to delete this event?')) {
      const bearerToken = new AuthService().getToken();
      if(bearerToken != null) {
        window.fetch(process.env.REACT_APP_EVENTS + '/' + eventId, {
          method: 'DELETE',
          headers: {
            Authorization: bearerToken,
          },
        })
          .then((response) => {
            if (response.status === 204) {
              this.setState({ items: this.state.items.filter(item => item.id !== eventId) });
            } else {
              throw new Error('Failed to delete event');
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }

    }
  }

  handleDownloadFile = (eventName, eventId) => {
    const bearerToken = new AuthService().getToken();
    if(bearerToken != null) {
      window.fetch(process.env.REACT_APP_EVENTS + '/' + eventId + '/payload', {
        method: 'GET',
        headers: {
          Authorization: bearerToken
        }
      })
        .then((response) => {
          if (response.ok) {
            return response.blob();
          } else {
            throw new Error('Failed to download file');
          }
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          if (eventName.toLowerCase() === 'vrgbd-app') {
            link.setAttribute('download', eventId + '.zip');
          } else {
            link.setAttribute('download', eventId);
          }
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  sortByFileDatePrefix(a,b) {
    if (a.created_at > b.created_at) return -1;
    if (b.created_at > a.created_at) return 1;
    return 0;
  }

  render() {
    const options = {
      year: 'numeric', month: 'short', day: 'numeric',
      hour: '2-digit', minute: 'numeric', second: 'numeric',
      hour12: false, timeZoneName: 'short', timeZone: 'UTC'
    };
    if((new AuthService()).loggedIn()) {
    return (
      <>
      <Container>
        <style>
            {`
              th {
                text-align: center;
                width: auto;
              }
              table {
                table-layout: auto;
                width: auto;
              }
            `}
        </style>
        <Table striped bordered>
          <thead>
            <tr><th colspan="12">{this.state.items.length} total events</th><th><Link to='/logout'>logout</Link></th></tr>
            <tr>
              <th>Created at</th>
              <th>UUID</th>
              <th>Name</th>
              <th>TCI</th>
              <th>Size</th>
              <th>Device ID</th>
              <th>OS</th>
              <th>Version</th>
              <th>Model</th>
              <th>Manufacturer</th>
              <th>IP address</th>
              <th>Download</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          {this.state.items.sort((a,b) => {
            let acreated = a.created_at
            let bcreated = b.created_at
            if (a.tci.match(/^[0-9a-f]+_(log|data)file/)) a.created_at = new Date(parseInt(a.tci.substring(0,11),16)).toISOString();
            if (b.tci.match(/^[0-9a-f]+_(log|data)file/)) b.created_at = new Date(parseInt(b.tci.substring(0,11),16)).toISOString();
            if (a.created_at > new Date().toISOString()) a.created_at = acreated;
            if (b.created_at > new Date().toISOString()) b.created_at = bcreated;
            if (b.created_at < a.created_at) return -1;
            if (a.created_at < b.created_at) return 1;
            return 0;
          }).map((item) => {
            return (
            <tr key={item.id}>
              <td>{Intl.DateTimeFormat('en-GB', options).format(Date.parse(item.created_at))}</td>
              <td>
                <a href="#" onClick={() => this.handleDownloadFile(item.name, item.id)} title="Click to download">{item.id}</a>
              </td>
              <td>{item.name}</td>
              <td>{ item.tci.includes('logfile')
                ? <Link to={'logfile/' + item.id} title={item.tci}>logfile</Link>
                : (item.processed ? <Link to={'datafile/' + item.id} title={item.tci}>datafile</Link> : <span>{item.tci}</span>)
              }</td>
              <td>{item.size}</td>
              <td><span title={item.deviceid}>{item.deviceid}</span></td>
              <td>{item.opsystem}</td>
              <td>{item.opversion}</td>
              <td>{item.model}</td>
              <td>{item.manufacturer}</td>
              <td><span title={item.ipaddress}>{item.ipaddress}</span></td>
              <td>{item.zipped ? <Link to={'zipfile/' + item.id}>download</Link> : "" }</td>
              <td>
                <Button variant="danger" onClick={() => this.handleDeleteEvent(item.id)}>
                    <span aria-hidden="true">&times;</span>
                </Button>
              </td>
            </tr>);})}
          </tbody>
        </Table>
      </Container>
      </>
    )
  }
  return (
    <Navigate to='/login' />
  )
  }
}

export default Events
