import React, { useState, useEffect, useCallback } from 'react';
import AuthService from './AuthService';
import './Events.css'

export default function Imagefile(props) {
  const [image,setImage] = useState();

  const showImage = useCallback((responseAsBlob) => {
    setImage(responseAsBlob);
    const imgUrl = URL.createObjectURL(responseAsBlob);
    const imageEl = document.querySelector(`#${props.elemid}`);
    imageEl.src = imgUrl;
  },[props.elemid])

  useEffect(() => {
    fetch(`${process.env.REACT_APP_EVENTS}/${props.imageid}/image`, {
      method: 'GET',
      headers: {
        Authorization: new AuthService().getToken()
      }
    })
    .then(resp => resp.blob())
    .then(data => showImage(data))
  },[props.imageid,showImage])

  const imageClick = () => {
    const imgUrl = URL.createObjectURL(image);
    const imageEl = document.querySelector('#mainimg');
    imageEl.src = imgUrl; imageEl.style.display = 'block';
  }

  return <>
    <img alt="fingerprint" width='200' height='250' id={props.elemid} onClick={() => imageClick()}/>
    &nbsp;&nbsp;
    </>

}
