import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Events from "./Events";
import Login from "./Login";
import Logout from "./Logout";
import Logfile from "./Logfile";
import Datafile from "./Datafile";
import Zipfile from './Zipfile';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Events />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logfile/:uuid" element={<Logfile />} />
        <Route path="/datafile/:uuid" element={<Datafile />} />
        <Route path="/zipfile/:uuid" element={<Zipfile />} />
        <Route path="/logout" element={<Logout />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
