import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import AuthService from './AuthService';
import Container from 'react-bootstrap/Container';
import Imagefile from './Imagefile';
import './Events.css';

export default function Datafile(props) {
  const [images, setImages] = useState([]);

  const { uuid } = useParams();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_EVENTS}/${uuid}/display`, {
      method: 'GET',
      headers: {
        Authorization: new AuthService().getToken()
      }
    })
    .then(resp => resp.json())
    .then(data => setImages(data))
  }, [uuid])

  const makeId = () => {
    let ID = "";
    let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for ( var i = 0; i < 12; i++ ) {
      ID += characters.charAt(Math.floor(Math.random() * 36));
    }
    return ID;
  }

  const imageClick = () => {
    const imageEl = document.querySelector('#mainimg');
    imageEl.style.display = 'none';
  }

  return <>
    <nav class="navbar navbar-expand-lg navbar-light bg-white">
    <Link to={'/zipfile/' + uuid}>Download</Link>
    </nav>
    <Container>
    <ul>
    {images.map((i)=>{return <Imagefile elemid={makeId()} imageid={i.id} />})}
    </ul>
    <img id="mainimg" height="600" width="600" alt="fingerprint"
      style={{display: 'none', position: 'absolute', top: '5%', left: '20%', right: 0, width: '60%', height: '100%', bottom: 0}}
      onClick={() => imageClick()}/>
    </Container>
    </>

}
